<template>
  <div class="applyshop">
    <!-- 主体 -->
    <div class="mainbody">
      <div class="mainbody-title">
        <span>小规模纳税人登记申请</span> -
        <span>{{ $route.query.regionName }}</span>
      </div>

      <div class="personal-info">
        <div class="info-name">姓名:&nbsp;&nbsp;{{ legalName }}</div>
        <div class="info">
          <div class="info-left">
            <div class="IDnum pd37">
              <p>身份证号:</p>
              <a-input style="width: 350px" placeholder="请输入身份证号" disabled v-model="idCard" :maxLength="18" />
            </div>
            <div class="politics pd37">
              <p>政治面貌:</p>
              <a-select placeholder="请选择政治面貌" :value="politicalStatus" style="width: 220px"
                @select="politicalStatus=$event">
                <a-select-option v-for="item in politics" :key="item">
                  {{ item }}
                </a-select-option>
              </a-select>
            </div>
            <div class="address pd37">
              <p>住所</p>
              <a-textarea placeholder="请输入住所地址" style="width: 350px" v-model="stayAddr"
                :auto-size="{ minRows: 3, maxRows: 3 }" />
            </div>
            <div class="Email pd37">
              <p>电子邮箱:</p>
              <a-input style="width: 350px" placeholder="请输入电子邮箱" v-model="email" />
            </div>
            <div class="postal-code pd37">
              <p>文化程度</p>
              <a-select placeholder="请选择文化程度" :value="eduLevel" style="width: 220px" @select="(e) => (eduLevel = e)">
                <a-select-option v-for="item in education" :key="item">
                  {{ item }}
                </a-select-option>
              </a-select>
            </div>
            <div class="small-scale pd37">
              <p class="small-scal-title">小规模纳税人登记名称</p>

              <a-row type="flex" align="middle" class="tp10">
                <a-col :span="4">{{ prefixName }}</a-col>
                <a-col :span="10">
                  <a-input class="pl" v-model="firstAltName" @change="firstError=false"></a-input>
                </a-col>
                <a-col :span="9" align="center">{{ suffixName }}</a-col>
                <a-col class="error" v-if="firstError">*请填写小规模纳税人登记名称</a-col>
              </a-row>
              <p>
                备选1：&nbsp;&nbsp;
                <a-input class="pl" v-model="secondAltName" />
              </p>

              <p>
                备选2：&nbsp;&nbsp;
                <a-input class="pl" v-model="thirdAltName" />
              </p>
            </div>
          </div>
          <div class="info-right">
            <div class="Phone pd37">
              <p>移动电话</p>
              <a-input v-model="bankReservedMobile" @input="bankReservedMobile=$event.target.value.replace(/[^\d]/g,'')"
                placeholder="请输入电话号码" :maxLength="11" style="width: 350px" />
            </div>
            <div class="nation pd37">
              <p>民族</p>
              <a-select placeholder="请选择民族" :value="belongNation" style="width: 180px" @select="belongNation = $event">
                <a-select-option v-for="item in nation" :key="item">
                  {{ item }}
                </a-select-option>
              </a-select>
            </div>
            <div class="postal-code pd37">
              <p>身份证有效期</p>
              <a-space>
                <!-- <a-range-picker format="YYYY-MM-DD" :value="[idCardStartDate, idCardEndDate]" @change="onIndateChange" class="w350" /> -->
                <a-date-picker v-model="idCardStartDate" placeholder="开始日期" :disabled-date="disabledStartDate" style="width: 135px">
                </a-date-picker>
                至
                <a-date-picker v-if="!long" v-model="idCardEndDate" placeholder="结束日期" :disabled-date="disabledEndDate" style="width: 135px">
                </a-date-picker>
                <a-checkbox v-model="long" @change="idCardEndDate = undefined">长期
                </a-checkbox>
              </a-space>
            </div>
            <div class="postal-code pd37 pd40">
              <p>邮政编码</p>
              <a-input placeholder="请输入邮政编码" v-model="postalCode"
                @input="postalCode=$event.target.value.replace(/[^\d]/g,'')" style="width: 350px" />
            </div>
            <div class="annotation">
            </div>
          </div>
        </div>
        <div class="signtory">
          <span>授权他人为该交易主体的合同签署人</span>
          &nbsp;&nbsp;
          <a-switch @change="$event ? (isAuthSigner = 1) : (isAuthSigner=0)" :checked="isAuthSigner ? true : false">
            <a-icon slot="checkedChildren" type="check" />
            <a-icon slot="unCheckedChildren" type="close" />
          </a-switch>
          <a-row type="flex" align="middle" class="ant-row" :class="!isAuthSigner ? 'opct' : ''">
            <a-col :span="2">签署人姓名:</a-col>
            <a-col :span="7">
              <a-input v-model="signerName" placeholder="姓名" class="notact-input"
                :class="signerName != '' ? 'activate-input' : ''" />
            </a-col>
            <a-col :span="2"></a-col>
            <a-col :span="2" align="center">身份证号: </a-col>
            <a-col :span="7">
              <a-input v-model="signerIdCard" placeholder="身份证号"
                @input="signerIdCard=$event.target.value.replace(/[^\d]/g,'')" class="notact-input" :maxLength="18"
                :class="signerIdCard != null ? 'activate-input' : ''" />
            </a-col>
            <a-col :span="5"></a-col>
          </a-row>
          <a-row type="flex" align="middle" class="ant-row" :class="!isAuthSigner ? 'opct' : ''">
            <a-col :span="2">签署手机号:</a-col>
            <a-col :span="7">
              <a-input v-model="signerMobile" placeholder="手机号"
                @input="signerMobile=$event.target.value.replace(/[^\d]/g,'')" :maxLength="11" class="notact-input"
                :class="signerMobile != null ? 'activate-input' : ''" />
            </a-col>
          </a-row>
        </div>
      </div>
      <div class="next">
        <a-space>
          <a-button @click="$router.back()">返回</a-button>
          <a-button type="primary" @click="next" :loading="loading">提交</a-button>
        </a-space>
      </div>
    </div>
  </div>
</template>
<script>
import { addapply } from "../../api/seller";
import { queryApplyDetail, editApplySave, echo } from "../../api/user";
import { selectregion, selectpurchaser } from "../../api/seller";
import { nation } from "../../api/utils";
import { politics, education } from '@/utils/constArr'
import moment from 'moment'
const regEmail = /.{1,}@.{1,}\..{1,}/
export default {
  data() {
    return {
      nation: nation(),
      steps: 2,
      reg: "个体工商户注册申请",
      site: "青岛胶州",
      politics,  //政治面貌
      education, //文化程度
      eduLevel: '高中', //文化程度
      company: [], //关联公司
      purchaserId: "", //关联采购商ID
      commercialName: '',       //关联公司名字

      sw: false,
      legalName: "", // 法人姓名
      idCard: "", // 法人身份证号
      sex: null, // 性别
      belongNation: "汉族", // 民族
      supplierName: "杭州91", // 供应商名称
      bankReservedMobile: "", // 银行预留手机号
      politicalStatus: '群众', // 政治面貌
      email: "", // 电子邮件
      postalCode: "", // 邮编
      prefixName: "", // 公司注册名前缀
      suffixName: "", // 公司注册名后缀
      firstAltName: "", // 第一工商名称
      firstError: false, // 第一工商名称未填警示
      secondAltName: "", // 第二工商名称
      thirdAltName: "", // 第三工商名称
      isAuthSigner: 0, // 是否授权人（0：不授权，1:授权）
      signerName: "", // 签署人姓名
      signerIdCard: null, // 签署人身份证号
      signerMobile: null, // 签署人手机号
      regionId: "", //祱区id
      taxRegionName: "",//祱区名称
      stayAddr: "", // 住所地址
      loading: false,
      idCardEndDate: undefined,
      idCardStartDate: undefined,
      error: {
        email: "请填写正确的邮箱",
        firstAltName: "请填写第一工商名称",
        signerName: "请填输入签署人姓名",
        signerIdCard: "请填输入签署人身份证号",
        signerMobile: "请填输入签署人手机号",
      },
      supplierId: null,
      id: null,
      long: false // 身份证长期
    };
  },
  created() {
    this.getID();
    // this.getface();
    if (this.$route.query.isedit) {
      queryApplyDetail(this.$route.query.regionId, this.$route.query.individualId).then(res => {
        console.log(2222, res)
        if (res.code === 200) {
          const data = res.data
          this.id = data.id
          this.legalName = data.legalName
          this.stayAddr = data.stayAddr
          this.postalCode = data.postalCode
          this.email = data.email
          this.firstAltName = data.firstAltName
          this.secondAltName = data.secondAltName
          this.thirdAltName = data.thirdAltName
          this.belongNation = data.belongNation || '汉族'
          this.signerName = data.signerName
          this.signerMobile = data.signerMobile
          this.signerIdCard = data.signerIdCard
          this.prefixName = data.prefixName
          this.suffixName = data.suffixName
          this.supplierId = data.supplierId
          this.politicalStatus = data.politicalStatus
          this.bankReservedMobile = data.bankReservedMobile
          this.idCard = data.idCard
          this.eduLevel = data.eduLevel || '高中'
          this.isAuthSigner = data.isAuthSigner
          if (res.data.idCardEndDate === '长期') {
            this.long = true
          } else {
            this.idCardEndDate = res.data.idCardEndDate
          }
          this.idCardStartDate = data.idCardStartDate
        } else {
          this.$message.error(res.message)
        }
      })
    } else {
      selectregion().then(res => {
        const data = res.data.find(item => {
          return item.id == this.$route.query.taxRegionId
        })
        console.log(data)
        data.config = JSON.parse(data.config)
        this.prefixName = data.config.registrationPrefix
        this.suffixName = data.config.registrationSuffix
        this.taxRegionName = data.regionName
        this.regionId = data.id
      })
      echo({ individualId: this.$route.query.individualId }).then(res => {
        if (res.code === 200) {
          const data = res.data
          this.legalName = data.legalName
          this.idCard = data.idCard
          this.belongNation = data.belongNation || '汉族'
          this.bankReservedMobile = data.bankReservedMobile
          this.politicalStatus = data.politicalStatus || '群众'
          this.email = data.email
          this.postalCode = data.postalCode
          this.eduLevel = data.eduLevel || '高中'
          this.stayAddr = data.stayAddr
          this.firstAltName = data.legalName
          this.idCardStartDate = data.idCardStartDate
          if (res.data.idCardEndDate === '长期') {
            this.long = true
          } else {
            this.idCardEndDate = res.data.idCardEndDate
          }
        }
      })
    }
  },
  methods: {
    // s祱区id获取
    onSearch(value) {
      if (value) {
        selectpurchaser({ commercialName: value }).then((res) => {
          this.company = res.data;
        });
      } else {
        this.$message.error("请填写关联公司");
      }
    },
    getID() {
      // this.sex = this.$route.query.sex;
      // this.bankReservedMobile = this.$route.query.mobile;
      // this.idCard = this.$route.query.idCard
      // this.legalName = this.$route.query.legalName
    },
    swt(e) {
      this.sw = e;
      if (e == true) {
        this.isAuthSigner = "1";
      } else {
        this.isAuthSigner = "0";
      }
    },
    getSelID(d) {
      this.commercialName = d.commercialName
      this.purchaserId = d.id;
    },
    next() {
      let verify = false;
      if (this.idCardStartDate && !this.long && !this.idCardEndDate) {
        this.$message.error('请填写正确的身份证有效期')
      } else if (this.email && !regEmail.test(this.email)) {
        this.$message.error(this.error.email);
      } else if (!this.firstAltName) {
        this.$message.error(this.error.firstAltName);
        this.firstError = true
      } else if (this.isAuthSigner == "1" && !this.signerName) {
        this.$message.error(this.error.signerName);
      } else if (this.isAuthSigner == "1" && !this.signerIdCard) {
        this.$message.error(this.error.signerIdCard);
      } else if (this.isAuthSigner == "1" && !this.signerMobile) {
        this.$message.error(this.error.signerMobile);
      } else {
        verify = true;
      }
      if (verify) {
        this.loading = true
        if (this.$route.query.isedit) {
          const config = {
            legalName: this.legalName,
            idCard: this.idCard,
            bankReservedMobile: this.bankReservedMobile,
            supplierName: this.prefixName + this.firstAltName + this.suffixName,
            supplierId: this.supplierId,
            individualApplyId: this.id,
            belongNation: this.belongNation,
            politicalStatus: this.politicalStatus,
            stayAddr: this.stayAddr,
            postalCode: this.postalCode,
            email: this.email,
            eduLevel: this.eduLevel,
            firstAltName: this.firstAltName,
            secondAltName: this.secondAltName,
            thirdAltName: this.thirdAltName,
            isAuthSigner: this.isAuthSigner,
            signerName: this.isAuthSigner ? this.signerName : undefined,
            signerIdCard: this.isAuthSigner ? this.signerIdCard : undefined,
            signerMobile: this.isAuthSigner ? this.signerMobile : undefined,
            idCardEndDate: this.long && this.idCardStartDate ? '长期' : (this.idCardStartDate ? moment(this.idCardEndDate).format('YYYY-MM-DD') : undefined),
            idCardStartDate: this.idCardStartDate ? moment(this.idCardStartDate).format('YYYY-MM-DD') : undefined
          };
          console.log(config);
          // 编辑
          editApplySave(config).then(res => {
            this.loading = false
            if (res.code === 200) {
              this.$message.success(res.message)
              this.$router.back()
            } else {
              this.$message.error(res.message);
            }
          }).catch(() => { this.loading = false })
        } else {
          const config = {
            legalName: this.legalName,
            idCard: this.idCard,
            sex: this.sex,
            belongNation: this.belongNation,
            supplierName: this.prefixName + this.firstAltName + this.suffixName,
            bankReservedMobile: this.bankReservedMobile,
            politicalStatus: this.politicalStatus,
            email: this.email,
            regionId: this.regionId,
            taxRegionName: this.taxRegionName,
            postalCode: this.postalCode,
            prefixName: this.prefixName,
            suffixName: this.suffixName,
            firstAltName: this.firstAltName,
            eduLevel: this.eduLevel,
            secondAltName: this.secondAltName,
            thirdAltName: this.thirdAltName,
            isAuthSigner: this.isAuthSigner,
            signerName: this.signerName,
            signerIdCard: this.signerIdCard,
            signerMobile: this.signerMobile,
            stayAddr: this.stayAddr,
            idCardEndDate: this.long && this.idCardStartDate ? '长期' : (this.idCardEndDate ? moment(this.idCardEndDate).format('YYYY-MM-DD') : undefined),
            idCardStartDate: this.idCardStartDate ? moment(this.idCardStartDate).format('YYYY-MM-DD') : undefined
          };
          // 添加
          addapply(config).then((res) => {
            this.loading = false
            if (res.code == 200) {
              this.$message.success(res.message);
              this.$router.back()
            } else {
              this.$message.error(res.message);
            }
          }).catch(() => { this.loading = false })
        }
      }
    },
    getval(e) {
      this.politicalStatus = e;
    },
    onIndateChange (m, e) {
      this.idCardStartDate = e[0]
      this.idCardEndDate = e[1]
    },
    disabledStartDate (current) {
      return current && this.idCardEndDate && current > moment(this.idCardEndDate)
    },
    disabledEndDate (current) {
        return current && this.idCardStartDate && current < moment(this.idCardStartDate)
    },
  },
};
</script>
<style lang="less" scoped>
.applyshop {
  margin-bottom: 100px;
  .mainbody {
    height: 1064px;
    background-color: #ffffff;
    margin: 20px auto;
    position: relative;
    .mainbody-title {
      :nth-child(1) {
        font-size: 20px;
      }
      color: rgba(0, 0, 0, 0.847058823529412);
      font-size: 16px;
      font-weight: 700;
    }
    .personal-info {
      .info-name {
        padding: 50px 0 0 30px;
        font-size: 20px;
        font-weight: 700;
        color: rgba(0, 0, 0, 0.847058823529412);
      }
      .info {
        display: flex;
        padding: 0 0 0 30px;
        font-size: 14px;
        font-weight: 400;
        .info-left {
          .IDnum {
            padding: 20px 0 35px 0;
          }
          .small-scale {
            .pl {
              width: 145px;
              padding: 0 0 0 10px !important;
            }
            p {
              padding: 10px 0 0 0;
            }
            :nth-child(1) {
              padding: 0;
            }
            .small-scal-title {
              font-size: 14px;
              font-weight: 700;
              color: rgba(0, 0, 0, 0.847058823529412);
            }
          }
        }
        .info-right {
          padding: 0 0 0 85px;
          .Phone {
            padding: 20px 0 35px 0;
          }
          .annotation {
            padding: 0 0 0 20px;
            p {
              padding: 20px 0 0 0;
              line-height: 20px;
              width: 375px;
            }
          }
        }
        .pd37 {
          padding: 0 0 30px 0;
          line-height: 30px;
        }
        .pd40 {
          padding-top: 40px;
        }
      }
      .signtory {
        padding: 0 0 0 30px;
        font-size: 15px;
        font-weight: 700;
        color: rgba(0, 0, 0, 0.847058823529412);
        .ant-row {
          font-size: 14px;
          font-weight: 400;
          padding: 15px 0;
          .notact-input {
            background-color: rgba(245, 245, 245, 1);
          }
          .activate-input {
            background-color: #ffffff;
          }
        }
        .opct {
          opacity: 0;
        }
      }
    }
    .tp10 {
      padding: 10px 0 0 0;
      position: relative;
      .error {
        width: 100%;
        position: absolute;
        right: -100%;
        color: red;
      }
    }
    .w54 {
      display: inline-block;
    }
    .next {
      width: 137px;
      height: 40px;
      cursor: pointer;
      font-size: 16px;
      font-weight: 400;
      border-radius: 4px;
      line-height: 40px;
      text-align: center;
      margin: 50px auto;
      color: #ffffff;
    }
  }
}
</style>